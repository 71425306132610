import { default as _91slug_935DklHblLDoMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/[vertical]/[slug].vue?macro=true";
import { default as indexrAL3lkBbX2Meta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/[vertical]/companies/index.vue?macro=true";
import { default as guides0a59FIQUllMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/[vertical]/guides.vue?macro=true";
import { default as _91response_932hUTMvMUUSMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/[vertical]/schedule-callback/[response].vue?macro=true";
import { default as indexA0w1BKDNfqMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/[vertical]/schedule-callback/index.vue?macro=true";
import { default as schedule_45callbackFFvcgPwcn3Meta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/[vertical]/schedule-callback.vue?macro=true";
import { default as about_45usmsy4GhpI0EMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/about-us.vue?macro=true";
import { default as callbacksMtpGQ18Z3Meta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/authorization/callback.vue?macro=true";
import { default as mergem3INcuDaynMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/authorization/merge.vue?macro=true";
import { default as comprehensiveAoz9pKv1weMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/car-insurance/comprehensive.vue?macro=true";
import { default as index9pzJvkeYUxMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/car-insurance/index.vue?macro=true";
import { default as _91step_93yGyKWnF95DMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/car-insurance/quote/form/[step].vue?macro=true";
import { default as detailsbmVJMXuncmMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/car-insurance/quote/form/details.vue?macro=true";
import { default as results1ZdOKInFc7Meta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/car-insurance/quote/form/results.vue?macro=true";
import { default as index1RoAzkZ0CQMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/car-loans/index.vue?macro=true";
import { default as indexwvHdsiVmkrMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/careers/index.vue?macro=true";
import { default as collection_45noticeXVYmZZk14yMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/collection-notice.vue?macro=true";
import { default as complaintsH6YQCBbKXuMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/complaints.vue?macro=true";
import { default as contact_45usvPjowb173rMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/contact-us.vue?macro=true";
import { default as _91slug_931ag9v8Fq0CMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/credit-cards/[slug].vue?macro=true";
import { default as indexMNLRbGokO6Meta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/credit-cards/index.vue?macro=true";
import { default as _91slug_93dn58Lah96cMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/credit-cards/preview/[slug].vue?macro=true";
import { default as _91slug_93hlmlwgCzo0Meta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/credit-cards/redirect/[slug].vue?macro=true";
import { default as account_45settings4uAaHdmddvMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/dashboard/account-settings.vue?macro=true";
import { default as indexLOgQgWtcHGMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/dashboard/index.vue?macro=true";
import { default as indexInyQ6V0JKLMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/dashboard/onboarding/index.vue?macro=true";
import { default as indexOYjnxaAaDuMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/dashboard/perks/index.vue?macro=true";
import { default as indexI4HFFHxrIAMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/dashboard/quotes/index.vue?macro=true";
import { default as indexnvntocUiJGMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/dashboard/rewards/index.vue?macro=true";
import { default as detailsQ1tsRYUTiYMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/dashboard/vault/[[product]]/details.vue?macro=true";
import { default as _91step_93uIwTfkbufJMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/dashboard/vault/[[product]]/form/[vertical]/[step].vue?macro=true";
import { default as additional_45detailspz2kzjQINSMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/dashboard/vault/[[product]]/form/[vertical]/additional-details.vue?macro=true";
import { default as completionTGGSQxH8tWMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/dashboard/vault/[[product]]/form/[vertical]/completion.vue?macro=true";
import { default as _91step_93sjJoqfMfkDMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/dashboard/vault/[[product]]/form/[vertical]/step/[step].vue?macro=true";
import { default as lic_45detailsqTiDWtDEHcMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/dashboard/vault/[[product]]/lic-details.vue?macro=true";
import { default as rebateMuamc12jVAMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/dashboard/vault/[[product]]/rebate.vue?macro=true";
import { default as indexsr0oP9wlsWMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/energy/index.vue?macro=true";
import { default as _91step_93iD4or9NOSJMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/energy/quote/form/[step].vue?macro=true";
import { default as details2aKODKwbE7Meta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/energy/quote/form/details.vue?macro=true";
import { default as thank_45youFNmjWvKfWnMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/energy/quote/thank-you.vue?macro=true";
import { default as _91slug_93CCDmSw74sxMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/expert-analysis/[category]/[slug].vue?macro=true";
import { default as indexrgcISMcoK6Meta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/expert-analysis/[category]/index.vue?macro=true";
import { default as _91author_933iDbLjBdNDMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/expert-analysis/author/[author].vue?macro=true";
import { default as indexjvY266BQYZMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/expert-analysis/index.vue?macro=true";
import { default as forgot_45password2fdztH8UwLMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/forgot-password.vue?macro=true";
import { default as _91step_93QjgvsHTUaOMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/health-insurance/application/[step].vue?macro=true";
import { default as expiredDCA8wF6ilaMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/health-insurance/application/payment/expired.vue?macro=true";
import { default as indexxrEsOpNKHRMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/health-insurance/application/payment/index.vue?macro=true";
import { default as indexNneyPjjQZIMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/health-insurance/application/thankyou/index.vue?macro=true";
import { default as errorYxiqZVPWXbMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/health-insurance/error.vue?macro=true";
import { default as indexI4tYhEdOBeMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/health-insurance/index.vue?macro=true";
import { default as live_45chat_45bvhYEkg70edMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/health-insurance/live-chat-b.vue?macro=true";
import { default as live_45chatC13vrIgigDMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/health-insurance/live-chat.vue?macro=true";
import { default as indexEJMKGlWwNxMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/health-insurance/offers/index.vue?macro=true";
import { default as addTYTTsCKXCWMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/health-insurance/partners/add.vue?macro=true";
import { default as signupkTAgHgiyIlMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/health-insurance/partners/signup.vue?macro=true";
import { default as post_45saledGoQKZt8xGMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/health-insurance/post-sale.vue?macro=true";
import { default as indexKjspm12BlNMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/health-insurance/quote/customer/index.vue?macro=true";
import { default as _91step_93Ioe0rK8uABMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/health-insurance/quote/form/[step].vue?macro=true";
import { default as detailsFgvRpvmV9EMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/health-insurance/quote/form/details.vue?macro=true";
import { default as indexxQgJrA5bxxMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/health-insurance/quote/resume/index.vue?macro=true";
import { default as thank_45youtwWBxxOSXMMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/health-insurance/quote/thank-you.vue?macro=true";
import { default as indexw4HOME9NZLMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/health-insurance/quotes-results/index.vue?macro=true";
import { default as indexk1lWyNw1AtMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/health-insurance/rate-rise-calculator/index.vue?macro=true";
import { default as indexxARWiLs01rMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/health-insurance/students-offer/[university]/form/index.vue?macro=true";
import { default as indexiLnwWes4HwMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/health-insurance/students-offer/[university]/terms-conditions/index.vue?macro=true";
import { default as _91step_93xZi9bnpHEUMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/[step].vue?macro=true";
import { default as _91step_931bg1qgxbO9Meta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/application/[step].vue?macro=true";
import { default as confirmationB6QZB24rbLMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/confirmation.vue?macro=true";
import { default as details9DrhUZZDlKMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/details.vue?macro=true";
import { default as indexj0I1E6GhOjMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/index.vue?macro=true";
import { default as quotes_45resultsZ9hANqdlFoMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/quotes-results.vue?macro=true";
import { default as thank_45youNq8hiiB0m8Meta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/thank-you.vue?macro=true";
import { default as _91type_93oOmvSpDPASMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/home-loans/calculators/[type].vue?macro=true";
import { default as indexDdXeDDyZ7yMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/home-loans/calculators/index.vue?macro=true";
import { default as indexbjJFV09WsTMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/home-loans/index.vue?macro=true";
import { default as interest_45rateswxEWPsZ84kMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/home-loans/interest-rates.vue?macro=true";
import { default as _91step_93xlktDbHauBMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/home-loans/quote/form/[step].vue?macro=true";
import { default as detailsX3rFE0AvkZMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/home-loans/quote/form/details.vue?macro=true";
import { default as thank_45you0YCO8UDKFyMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/home-loans/quote/thank-you.vue?macro=true";
import { default as indexkpGLgfrclAMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/index.vue?macro=true";
import { default as confirmation8ox00xMHbDMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/life-insurance/income-protection/quote/confirmation.vue?macro=true";
import { default as detailsS4Y7DVR0YcMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/life-insurance/income-protection/quote/details.vue?macro=true";
import { default as indexoiRAYfaLGMMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/life-insurance/income-protection/quote/index.vue?macro=true";
import { default as indexVXdyCDZiwBMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/life-insurance/index.vue?macro=true";
import { default as addIcg2DaHMhIMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/life-insurance/partners/add.vue?macro=true";
import { default as thank_45youwBVfbMBllSMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/life-insurance/partners/thank-you.vue?macro=true";
import { default as indexDaB5LBU6mUMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/life-insurance/quote/customer/index.vue?macro=true";
import { default as successbLAn8E9saHMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/life-insurance/quote/customer/success.vue?macro=true";
import { default as _91step_93Oyy4iTeQdkMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/life-insurance/quote/form/[step].vue?macro=true";
import { default as details8gmk7KxacKMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/life-insurance/quote/form/details.vue?macro=true";
import { default as loginEwSBor8Y3hMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/login.vue?macro=true";
import { default as _91slug_93VXVVsz9DSwMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/media/[slug].vue?macro=true";
import { default as _91author_93S7TyFkFcV4Meta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/media/author/[author].vue?macro=true";
import { default as indexj9TXKtjyCQMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/media/index.vue?macro=true";
import { default as partnershipsSLMyMfX82FMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/partnerships.vue?macro=true";
import { default as indexhHmIhioy7HMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/personal-loans/index.vue?macro=true";
import { default as post_45sale_45thank_45you7aVTYeGvd1Meta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/post-sale-thank-you.vue?macro=true";
import { default as _91slug_93wiLKbFO3a9Meta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/preview/[vertical]/[slug].vue?macro=true";
import { default as _91slug_93SdNOCIwm1iMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/preview/expert-analysis/[vertical]/[slug].vue?macro=true";
import { default as _91slug_93YI4j9n9XaXMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/preview/media/[slug].vue?macro=true";
import { default as privacy_45policytyIniTfzigMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/privacy-policy.vue?macro=true";
import { default as registerHtM4kys5tMMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/register.vue?macro=true";
import { default as reset_45passwordGnaDKfr4xEMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/reset-password.vue?macro=true";
import { default as terms_45of_45usePT6CkWZPkQMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/terms-of-use.vue?macro=true";
import { default as verify7phgjyEUCZMeta } from "/codebuild/output/src1730993911/src/packages/nuxt/pages/verify.vue?macro=true";
export default [
  {
    name: "vertical-slug",
    path: "/:vertical()/:slug()",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/[vertical]/[slug].vue")
  },
  {
    name: "vertical-companies",
    path: "/:vertical()/companies",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/[vertical]/companies/index.vue")
  },
  {
    name: "vertical-guides",
    path: "/:vertical()/guides",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/[vertical]/guides.vue")
  },
  {
    name: schedule_45callbackFFvcgPwcn3Meta?.name,
    path: "/:vertical()/schedule-callback",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/[vertical]/schedule-callback.vue"),
    children: [
  {
    name: "vertical-schedule-callback-response",
    path: ":response()",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/[vertical]/schedule-callback/[response].vue")
  },
  {
    name: "vertical-schedule-callback",
    path: "",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/[vertical]/schedule-callback/index.vue")
  }
]
  },
  {
    name: "about-us",
    path: "/about-us",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/about-us.vue")
  },
  {
    name: "authorization-callback",
    path: "/authorization/callback",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/authorization/callback.vue")
  },
  {
    name: "authorization-merge",
    path: "/authorization/merge",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/authorization/merge.vue")
  },
  {
    name: "car-insurance-comprehensive",
    path: "/car-insurance/comprehensive",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/car-insurance/comprehensive.vue")
  },
  {
    name: "car-insurance",
    path: "/car-insurance",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/car-insurance/index.vue")
  },
  {
    name: "car-insurance-quote-form-step",
    path: "/car-insurance/quote/form/:step()",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/car-insurance/quote/form/[step].vue")
  },
  {
    name: "car-insurance-quote-form-details",
    path: "/car-insurance/quote/form/details",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/car-insurance/quote/form/details.vue")
  },
  {
    name: "car-insurance-quote-form-results",
    path: "/car-insurance/quote/form/results",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/car-insurance/quote/form/results.vue")
  },
  {
    name: "car-loans",
    path: "/car-loans",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/car-loans/index.vue")
  },
  {
    name: "careers",
    path: "/careers",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/careers/index.vue")
  },
  {
    name: "collection-notice",
    path: "/collection-notice",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/collection-notice.vue")
  },
  {
    name: "complaints",
    path: "/complaints",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/complaints.vue")
  },
  {
    name: "contact-us",
    path: "/contact-us",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/contact-us.vue")
  },
  {
    name: "credit-cards-slug",
    path: "/credit-cards/:slug()",
    meta: _91slug_931ag9v8Fq0CMeta || {},
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/credit-cards/[slug].vue")
  },
  {
    name: "credit-cards",
    path: "/credit-cards",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/credit-cards/index.vue")
  },
  {
    name: "credit-cards-preview-slug",
    path: "/credit-cards/preview/:slug()",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/credit-cards/preview/[slug].vue")
  },
  {
    name: "credit-cards-redirect-slug",
    path: "/credit-cards/redirect/:slug()",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/credit-cards/redirect/[slug].vue")
  },
  {
    name: "dashboard-account-settings",
    path: "/dashboard/account-settings",
    meta: account_45settings4uAaHdmddvMeta || {},
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/dashboard/account-settings.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: indexLOgQgWtcHGMeta || {},
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/dashboard/index.vue")
  },
  {
    name: "dashboard-onboarding",
    path: "/dashboard/onboarding",
    meta: indexInyQ6V0JKLMeta || {},
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/dashboard/onboarding/index.vue")
  },
  {
    name: "dashboard-perks",
    path: "/dashboard/perks",
    meta: indexOYjnxaAaDuMeta || {},
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/dashboard/perks/index.vue")
  },
  {
    name: "dashboard-quotes",
    path: "/dashboard/quotes",
    meta: indexI4HFFHxrIAMeta || {},
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/dashboard/quotes/index.vue")
  },
  {
    name: "dashboard-rewards",
    path: "/dashboard/rewards",
    meta: indexnvntocUiJGMeta || {},
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/dashboard/rewards/index.vue")
  },
  {
    name: "dashboard-vault-product-details",
    path: "/dashboard/vault/:product?/details",
    meta: detailsQ1tsRYUTiYMeta || {},
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/dashboard/vault/[[product]]/details.vue")
  },
  {
    name: "dashboard-vault-product-form-vertical-step",
    path: "/dashboard/vault/:product?/form/:vertical()/:step()",
    meta: _91step_93uIwTfkbufJMeta || {},
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/dashboard/vault/[[product]]/form/[vertical]/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-vertical-additional-details",
    path: "/dashboard/vault/:product?/form/:vertical()/additional-details",
    meta: additional_45detailspz2kzjQINSMeta || {},
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/dashboard/vault/[[product]]/form/[vertical]/additional-details.vue")
  },
  {
    name: "dashboard-vault-product-form-vertical-completion",
    path: "/dashboard/vault/:product?/form/:vertical()/completion",
    meta: completionTGGSQxH8tWMeta || {},
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/dashboard/vault/[[product]]/form/[vertical]/completion.vue")
  },
  {
    name: "dashboard-vault-product-form-vertical-step-step",
    path: "/dashboard/vault/:product?/form/:vertical()/step/:step()",
    meta: _91step_93sjJoqfMfkDMeta || {},
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/dashboard/vault/[[product]]/form/[vertical]/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-lic-details",
    path: "/dashboard/vault/:product?/lic-details",
    meta: lic_45detailsqTiDWtDEHcMeta || {},
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/dashboard/vault/[[product]]/lic-details.vue")
  },
  {
    name: "dashboard-vault-product-rebate",
    path: "/dashboard/vault/:product?/rebate",
    meta: rebateMuamc12jVAMeta || {},
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/dashboard/vault/[[product]]/rebate.vue")
  },
  {
    name: "energy",
    path: "/energy",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/energy/index.vue")
  },
  {
    name: "energy-quote-form-step",
    path: "/energy/quote/form/:step()",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/energy/quote/form/[step].vue")
  },
  {
    name: "energy-quote-form-details",
    path: "/energy/quote/form/details",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/energy/quote/form/details.vue")
  },
  {
    name: "energy-quote-thank-you",
    path: "/energy/quote/thank-you",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/energy/quote/thank-you.vue")
  },
  {
    name: "expert-analysis-category-slug",
    path: "/expert-analysis/:category()/:slug()",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/expert-analysis/[category]/[slug].vue")
  },
  {
    name: "expert-analysis-category",
    path: "/expert-analysis/:category()",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/expert-analysis/[category]/index.vue")
  },
  {
    name: "expert-analysis-author-author",
    path: "/expert-analysis/author/:author()",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/expert-analysis/author/[author].vue")
  },
  {
    name: "expert-analysis",
    path: "/expert-analysis",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/expert-analysis/index.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45password2fdztH8UwLMeta || {},
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/forgot-password.vue")
  },
  {
    name: "health-insurance-application-step",
    path: "/health-insurance/application/:step()",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/health-insurance/application/[step].vue")
  },
  {
    name: "health-insurance-application-payment-expired",
    path: "/health-insurance/application/payment/expired",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/health-insurance/application/payment/expired.vue")
  },
  {
    name: "health-insurance-application-payment",
    path: "/health-insurance/application/payment",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/health-insurance/application/payment/index.vue")
  },
  {
    name: "health-insurance-application-thankyou",
    path: "/health-insurance/application/thankyou",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/health-insurance/application/thankyou/index.vue")
  },
  {
    name: "health-insurance-error",
    path: "/health-insurance/error",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/health-insurance/error.vue")
  },
  {
    name: "health-insurance",
    path: "/health-insurance",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/health-insurance/index.vue")
  },
  {
    name: "health-insurance-live-chat-b",
    path: "/health-insurance/live-chat-b",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/health-insurance/live-chat-b.vue")
  },
  {
    name: "health-insurance-live-chat",
    path: "/health-insurance/live-chat",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/health-insurance/live-chat.vue")
  },
  {
    name: "health-insurance-offers",
    path: "/health-insurance/offers",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/health-insurance/offers/index.vue")
  },
  {
    name: "health-insurance-partners-add",
    path: "/health-insurance/partners/add",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/health-insurance/partners/add.vue")
  },
  {
    name: "health-insurance-partners-signup",
    path: "/health-insurance/partners/signup",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/health-insurance/partners/signup.vue")
  },
  {
    name: "health-insurance-post-sale",
    path: "/health-insurance/post-sale",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/health-insurance/post-sale.vue")
  },
  {
    name: "health-insurance-quote-customer",
    path: "/health-insurance/quote/customer",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/health-insurance/quote/customer/index.vue")
  },
  {
    name: "health-insurance-quote-form-step",
    path: "/health-insurance/quote/form/:step()",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/health-insurance/quote/form/[step].vue")
  },
  {
    name: "health-insurance-quote-form-details",
    path: "/health-insurance/quote/form/details",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/health-insurance/quote/form/details.vue")
  },
  {
    name: "health-insurance-quote-resume",
    path: "/health-insurance/quote/resume",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/health-insurance/quote/resume/index.vue")
  },
  {
    name: "health-insurance-quote-thank-you",
    path: "/health-insurance/quote/thank-you",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/health-insurance/quote/thank-you.vue")
  },
  {
    name: "health-insurance-quotes-results",
    path: "/health-insurance/quotes-results",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/health-insurance/quotes-results/index.vue")
  },
  {
    name: "health-insurance-rate-rise-calculator",
    path: "/health-insurance/rate-rise-calculator",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/health-insurance/rate-rise-calculator/index.vue")
  },
  {
    name: "health-insurance-students-offer-university-form",
    path: "/health-insurance/students-offer/:university()/form",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/health-insurance/students-offer/[university]/form/index.vue")
  },
  {
    name: "health-insurance-students-offer-university-terms-conditions",
    path: "/health-insurance/students-offer/:university()/terms-conditions",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/health-insurance/students-offer/[university]/terms-conditions/index.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-step",
    path: "/health-insurance/visitors-visa-ovhc/:step()",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/[step].vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-application-step",
    path: "/health-insurance/visitors-visa-ovhc/application/:step()",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/application/[step].vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-confirmation",
    path: "/health-insurance/visitors-visa-ovhc/confirmation",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/confirmation.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-details",
    path: "/health-insurance/visitors-visa-ovhc/details",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/details.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc",
    path: "/health-insurance/visitors-visa-ovhc",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/index.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-quotes-results",
    path: "/health-insurance/visitors-visa-ovhc/quotes-results",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/quotes-results.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-thank-you",
    path: "/health-insurance/visitors-visa-ovhc/thank-you",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/thank-you.vue")
  },
  {
    name: "home-loans-calculators-type",
    path: "/home-loans/calculators/:type()",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/home-loans/calculators/[type].vue")
  },
  {
    name: "home-loans-calculators",
    path: "/home-loans/calculators",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/home-loans/calculators/index.vue")
  },
  {
    name: "home-loans",
    path: "/home-loans",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/home-loans/index.vue")
  },
  {
    name: "home-loans-interest-rates",
    path: "/home-loans/interest-rates",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/home-loans/interest-rates.vue")
  },
  {
    name: "home-loans-quote-form-step",
    path: "/home-loans/quote/form/:step()",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/home-loans/quote/form/[step].vue")
  },
  {
    name: "home-loans-quote-form-details",
    path: "/home-loans/quote/form/details",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/home-loans/quote/form/details.vue")
  },
  {
    name: "home-loans-quote-thank-you",
    path: "/home-loans/quote/thank-you",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/home-loans/quote/thank-you.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/index.vue")
  },
  {
    name: "life-insurance-income-protection-quote-confirmation",
    path: "/life-insurance/income-protection/quote/confirmation",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/life-insurance/income-protection/quote/confirmation.vue")
  },
  {
    name: "life-insurance-income-protection-quote-details",
    path: "/life-insurance/income-protection/quote/details",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/life-insurance/income-protection/quote/details.vue")
  },
  {
    name: "life-insurance-income-protection-quote",
    path: "/life-insurance/income-protection/quote",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/life-insurance/income-protection/quote/index.vue")
  },
  {
    name: "life-insurance",
    path: "/life-insurance",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/life-insurance/index.vue")
  },
  {
    name: "life-insurance-partners-add",
    path: "/life-insurance/partners/add",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/life-insurance/partners/add.vue")
  },
  {
    name: "life-insurance-partners-thank-you",
    path: "/life-insurance/partners/thank-you",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/life-insurance/partners/thank-you.vue")
  },
  {
    name: "life-insurance-quote-customer",
    path: "/life-insurance/quote/customer",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/life-insurance/quote/customer/index.vue")
  },
  {
    name: "life-insurance-quote-customer-success",
    path: "/life-insurance/quote/customer/success",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/life-insurance/quote/customer/success.vue")
  },
  {
    name: "life-insurance-quote-form-step",
    path: "/life-insurance/quote/form/:step()",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/life-insurance/quote/form/[step].vue")
  },
  {
    name: "life-insurance-quote-form-details",
    path: "/life-insurance/quote/form/details",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/life-insurance/quote/form/details.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginEwSBor8Y3hMeta || {},
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/login.vue")
  },
  {
    name: "media-slug",
    path: "/media/:slug()",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/media/[slug].vue")
  },
  {
    name: "media-author-author",
    path: "/media/author/:author()",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/media/author/[author].vue")
  },
  {
    name: "media",
    path: "/media",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/media/index.vue")
  },
  {
    name: "partnerships",
    path: "/partnerships",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/partnerships.vue")
  },
  {
    name: "personal-loans",
    path: "/personal-loans",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/personal-loans/index.vue")
  },
  {
    name: "post-sale-thank-you",
    path: "/post-sale-thank-you",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/post-sale-thank-you.vue")
  },
  {
    name: "preview-vertical-slug",
    path: "/preview/:vertical()/:slug()",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/preview/[vertical]/[slug].vue")
  },
  {
    name: "preview-expert-analysis-vertical-slug",
    path: "/preview/expert-analysis/:vertical()/:slug()",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/preview/expert-analysis/[vertical]/[slug].vue")
  },
  {
    name: "preview-media-slug",
    path: "/preview/media/:slug()",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/preview/media/[slug].vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/privacy-policy.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: registerHtM4kys5tMMeta || {},
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/register.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: reset_45passwordGnaDKfr4xEMeta || {},
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/reset-password.vue")
  },
  {
    name: "terms-of-use",
    path: "/terms-of-use",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/terms-of-use.vue")
  },
  {
    name: "verify",
    path: "/verify",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/verify.vue")
  },
  {
    name: "Guides",
    path: "/credit-cards/guides",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/[vertical]/guides.vue")
  },
  {
    name: "Companies",
    path: "/credit-cards/companies",
    component: () => import("/codebuild/output/src1730993911/src/packages/nuxt/pages/[vertical]/companies/index.vue")
  }
]